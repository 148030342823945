import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {AppColumnMenuBodyTemplate, AppContext, TwoDataTable, TwoToast} from 'two-app-ui';
import {B2bIntegration, B2bRequest, QueryParameter} from 'two-core';
import formats from '../../config/formats';
import B2bRequestsService from '../../services/B2bRequestsService';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';

interface Props {
  b2bIntegrationId?: number;
}

interface State {
  loading: boolean;
  showNewDialog?: boolean;
  b2bRequests: B2bIntegration[];
  totalB2bRequests: number;
  filter?: {
    [key: string]: string | DateColumnFilterChangeEvent;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
}

export default class B2bRequestList extends Component<Props, State> {
  static contextType = AppContext;

  b2bRequestsService?: B2bRequestsService;
  twoToast?: TwoToast;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      b2bRequests: [],
      totalB2bRequests: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: {field: 'received_at', order: -1},
    };
    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onHideNewDialog = this.onHideNewDialog.bind(this);
  }

  componentDidMount() {
    this.b2bRequestsService = this.context.b2bRequestsService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    const {filter, sortBy, pagination} = this.state;
    this.setState({loading: true});
    const {b2bRequests, totalB2bRequests} = await this.loadB2bRequests(
      filter,
      sortBy,
      pagination.pageSize,
      pagination.offset
    );
    this.setState({loading: false, b2bRequests, totalB2bRequests: totalB2bRequests ?? 0});
  }

  async loadB2bRequests(
    filter?: {[key: string]: string | DateColumnFilterChangeEvent},
    sortBy?: {
      field: string;
      order: DataTableSortOrderType;
    },
    pageSize?: number,
    offset?: number
  ) {
    try {
      const filters: string[] = [];
      if (filter?.name) {
        filters.push(JSON.stringify({field: 'name', value: filter.name, condition: 'iLike'}));
      }
      if (filter?.manager_key) {
        filters.push(JSON.stringify({field: 'manager_key', value: filter.manager_key, condition: 'iLike'}));
      }
      if (filter?.token) {
        filters.push(
          JSON.stringify({
            orConditions: [
              {
                field: 'token.access_token',
                value: filter.token,
                condition: 'iLike',
              },
              {
                field: 'token.from_email',
                value: filter.token,
                condition: 'iLike',
              },
            ],
          })
        );
      }

      let orderBys = undefined;
      if (sortBy) {
        orderBys = [JSON.stringify({field: sortBy.field, direction: sortBy.order === 1 ? 'asc' : 'desc'})];
      }
      const queryParams: QueryParameter = {
        page_size: pageSize,
        offset,
        orderBys,
        filters,
      };
      const response = await this.b2bRequestsService?.getB2bRequests(queryParams);
      const b2bRequests = (response?.records ?? []) as B2bIntegration[];
      const totalB2bRequests = response?.total_records ?? 0;
      return {b2bRequests: b2bRequests, totalB2bRequests};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading b2b requests');
      return {b2bRequests: [], totalB2bRequests: 0};
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(name: string, value: string | DateColumnFilterChangeEvent) {
    await this.setState({filter: {...this.state.filter, [name]: value}});
    this.loadData();
  }

  onHideNewDialog() {
    this.setState({showNewDialog: false});
    this.loadData();
  }

  receivedBody(rowData: B2bRequest) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        selectedItems={[]}
      >
        <NavLink to={'/b2b-request/' + rowData.id}>
          {rowData.received_at ? DateTime.fromISO(rowData.received_at?.toString()).toFormat(formats.dateTime) : ''}
        </NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  mappedBody(rowData: B2bRequest) {
    return (
      <span>{rowData.mapped_at ? DateTime.fromISO(rowData.mapped_at?.toString()).toFormat(formats.dateTime) : ''}</span>
    );
  }

  respondedBody(rowData: B2bRequest) {
    return (
      <span>
        {rowData.responded_at ? DateTime.fromISO(rowData.responded_at?.toString()).toFormat(formats.dateTime) : ''}
      </span>
    );
  }

  dateFilterTemplate(attributeName: string) {
    return (
      <DateColumnFilter
        name="ecd"
        value={this.state.filter?.[attributeName] as unknown as {fromDate: DateTime | null; toDate: DateTime | null}}
        onChange={e => this.onFilterChange(attributeName, e)}
      />
    );
  }

  inputTextFilterTemplate(attributeName: string) {
    return (
      <InputText
        name={attributeName}
        className="form-filter"
        onBlur={e => this.onFilterChange(attributeName, e.target.value)}
      />
    );
  }

  render() {
    const {loading, b2bRequests, totalB2bRequests, pagination, sortBy, showNewDialog} = this.state;
    console.debug('b2bRequests', b2bRequests);
    return (
      <div className="b2b-requests-table">
        <TwoDataTable
          loading={loading}
          value={b2bRequests}
          activeFilters={{}}
          selectedItems={[]}
          rows={pagination.pageSize}
          first={pagination.offset}
          totalRecords={totalB2bRequests}
          onSort={this.onSort}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={this.onPageChange}
        >
          <Column
            header="Received"
            body={this.receivedBody}
            field="received_at"
            sortable
            filter
            filterElement={this.dateFilterTemplate('received_at')}
            showFilterMenu={false}
          />
          <Column
            header="Mapped"
            body={this.mappedBody}
            field="mapped_at"
            sortable
            filter
            filterElement={this.dateFilterTemplate('mapped_at')}
            showFilterMenu={false}
          />
          <Column
            header="Responded"
            body={this.respondedBody}
            field="responded_at"
            sortable
            filter
            filterElement={this.dateFilterTemplate('responded_at')}
            showFilterMenu={false}
          />
          <Column
            header="Order"
            field="order_id"
            sortable
            filter
            filterElement={this.inputTextFilterTemplate('order_id')}
            showFilterMenu={false}
          />
          <Column header="Response" field="response.code" />
        </TwoDataTable>
      </div>
    );
  }
}
