import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MenuItem} from 'primereact/menuitem';
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {AppColumnMenuBodyTemplate, AppContext, TwoDataTable, TwoToast} from 'two-app-ui';
import {B2bIntegration, QueryParameter} from 'two-core';
import B2bIntegrationsService from '../../services/B2bIntegrationsService';
import {Dropdown} from 'primereact/dropdown';
import EditB2bIntegrationDialog from './EditB2bIntegrationDialog/EditB2bIntegrationDialog';

interface State {
  loading: boolean;
  showNewDialog?: boolean;
  b2bIntegrations: B2bIntegration[];
  totalB2bIntegrations: number;
  filter?: {
    [key: string]: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
}

export default class B2bIntegrationList extends Component<{}, State> {
  static contextType = AppContext;

  b2bIntegrationsService?: B2bIntegrationsService;
  twoToast?: TwoToast;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      b2bIntegrations: [],
      totalB2bIntegrations: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
    };
    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.nameBody = this.nameBody.bind(this);
    this.headerMenuItems = this.headerMenuItems.bind(this);
    this.rowMenuItems = this.rowMenuItems.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.enabledFilter = this.enabledFilter.bind(this);
    this.tokenBody = this.tokenBody.bind(this);
    this.enabledBody = this.enabledBody.bind(this);
    this.onHideNewDialog = this.onHideNewDialog.bind(this);
  }

  componentDidMount() {
    this.b2bIntegrationsService = this.context.b2bIntegrationsService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    const {filter, sortBy, pagination} = this.state;
    this.setState({loading: true});
    const {b2bIntegrations, totalB2bIntegrations} = await this.loadB2bIntegrations(
      filter,
      sortBy,
      pagination.pageSize,
      pagination.offset
    );
    this.setState({loading: false, b2bIntegrations, totalB2bIntegrations: totalB2bIntegrations ?? 0});
  }

  async loadB2bIntegrations(
    filter?: {[key: string]: string},
    sortBy?: {
      field: string;
      order: DataTableSortOrderType;
    },
    pageSize?: number,
    offset?: number
  ) {
    try {
      const filters: string[] = [];
      if (filter?.name) {
        filters.push(JSON.stringify({field: 'name', value: filter.name, condition: 'iLike'}));
      }
      if (filter?.manager_key) {
        filters.push(JSON.stringify({field: 'manager_key', value: filter.manager_key, condition: 'iLike'}));
      }
      if (filter?.token) {
        filters.push(
          JSON.stringify({
            orConditions: [
              {
                field: 'token.access_token',
                value: filter.token,
                condition: 'iLike',
              },
              {
                field: 'token.from_email',
                value: filter.token,
                condition: 'iLike',
              },
            ],
          })
        );
      }
      if (filter?.enabled) {
        if (filter.enabled === 'Enabled') {
          filters.push(JSON.stringify({field: 'enabled', value: true}));
        } else if (filter.enabled === 'Disabled') {
          filters.push(JSON.stringify({field: 'enabled', value: true, condition: '<>'}));
        }
      }

      let orderBys = undefined;
      if (sortBy) {
        orderBys = [JSON.stringify({field: sortBy.field, direction: sortBy.order === 1 ? 'asc' : 'desc'})];
      }
      const queryParams: QueryParameter = {
        page_size: pageSize,
        offset,
        orderBys,
        filters,
      };
      const response = await this.b2bIntegrationsService?.getB2bIntegrations(queryParams);
      const b2bIntegrations = (response?.records ?? []) as B2bIntegration[];
      const totalB2bIntegrations = response?.total_records ?? 0;
      return {b2bIntegrations, totalB2bIntegrations};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading b2b integrations');
      return {b2bIntegrations: [], totalStockTakes: 0};
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(name: string, value: string) {
    await this.setState({filter: {...this.state.filter, [name]: value}});
    this.loadData();
  }

  onHideNewDialog() {
    this.setState({showNewDialog: false});
    this.loadData();
  }
  headerMenuItems(): MenuItem[] {
    const menuItems: MenuItem[] = [];
    menuItems.push({
      label: 'Add',
      icon: 'pi pi-plus',
      command: () => this.setState({showNewDialog: true}),
    });
    return menuItems;
  }

  rowMenuItems(b2bIntegration: B2bIntegration) {
    return [];
  }

  nameBody(rowData: B2bIntegration) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.rowMenuItems(rowData)}
        selectedItems={[]}
      >
        <NavLink to={'/b2b-integration/' + rowData.id}>{rowData.name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  tokenBody(rowData: B2bIntegration) {
    if (rowData.token.type === 'api') {
      return rowData.token.access_token;
    }
    if (rowData.token.type === 'email') {
      return rowData.token.email_from;
    }
    return '';
  }

  enabledBody(rowData: B2bIntegration) {
    return rowData.enabled ? 'Enabled' : 'Disabled';
  }

  inputTextFilterTemplate(attributeName: string) {
    return (
      <InputText
        name={attributeName}
        className="form-filter"
        onBlur={e => this.onFilterChange(attributeName, e.target.value)}
      />
    );
  }

  enabledFilter() {
    const {filter} = this.state;
    return (
      <Dropdown
        value={filter?.enabled}
        options={['Enabled', 'Disabled']}
        name="stage"
        className="form-filter"
        onChange={e => this.onFilterChange('enabled', e.target.value)}
        showClear
      />
    );
  }

  render() {
    const {loading, b2bIntegrations, totalB2bIntegrations, pagination, sortBy, showNewDialog} = this.state;
    return (
      <div className="b2b-integrations-table">
        <TwoDataTable
          loading={loading}
          value={b2bIntegrations}
          activeFilters={{}}
          selectedItems={[]}
          rows={pagination.pageSize}
          first={pagination.offset}
          totalRecords={totalB2bIntegrations}
          onSort={this.onSort}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={this.onPageChange}
          initMenuItems={this.headerMenuItems}
        >
          <Column
            header="Name"
            body={this.nameBody}
            sortField="name"
            sortable
            filter
            filterElement={this.inputTextFilterTemplate('name')}
            showFilterMenu={false}
          />
          <Column
            header="Token"
            body={this.tokenBody}
            filter
            filterElement={this.inputTextFilterTemplate('token')}
            showFilterMenu={false}
          />
          <Column
            header="Manager"
            field="manager_key"
            filter
            filterElement={this.inputTextFilterTemplate('manager_key')}
            showFilterMenu={false}
          />
          <Column
            header="Enabled"
            body={this.enabledBody}
            filter
            filterElement={this.enabledFilter()}
            showFilterMenu={false}
            sortable
          />
        </TwoDataTable>
        <EditB2bIntegrationDialog
          showDialog={showNewDialog ?? false}
          onHide={() => this.setState({showNewDialog: false}, () => this.loadData())}
        />
      </div>
    );
  }
}
