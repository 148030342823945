import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {B2bApiToken, B2bEmailToken, B2bTokenType} from 'two-core';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import formats from '../../../config/formats';
import React from 'react';

const tokenTypeOptions: B2bTokenType[] = ['api', 'email'];
interface Props {
  token?: B2bApiToken | B2bEmailToken;
  onTokenChange: (tokenPatch: Partial<B2bApiToken | B2bEmailToken>) => void;
}
export const TokenSection = ({token, onTokenChange}: Props) => {
  const generateToken = (length = 64) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  let accessTokenExpires = undefined;
  let refreshTokenExpires = undefined;
  if (token?.type === 'api') {
    if (token?.access_token_expires) {
      accessTokenExpires = new Date(token.access_token_expires);
    }
    if (token?.refresh_token_expires) {
      refreshTokenExpires = new Date(token.refresh_token_expires);
    }
  }
  return (
    <>
      <div className="p-field p-grid">
        <label className="p-col-2">token type</label>
        <div className="p-col-10">
          <Dropdown value={token?.type} options={tokenTypeOptions} onChange={e => onTokenChange({type: e.value})} />
        </div>
      </div>
      {token?.type === 'api' && (
        <>
          <div className="p-field p-grid">
            <label className="p-col-2">access token</label>
            <div className="p-col-6">
              <div className="p-inputgroup">
                <InputText value={token?.access_token} onChange={e => onTokenChange({access_token: e.target.value})} />
                <Button label="generate" onClick={() => onTokenChange({access_token: generateToken()})} />
              </div>
            </div>
            <label className="p-col-2">expires</label>
            <div className="p-col-2">
              <Calendar
                value={accessTokenExpires}
                onChange={e => onTokenChange({access_token_expires: e.value as Date | undefined})}
                showIcon
                dateFormat={formats.calendarInputDate}
                showTime
              />
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2">refresh token</label>
            <div className="p-col-6">
              <div className="p-inputgroup">
                <InputText
                  value={token?.refresh_token}
                  onChange={e => onTokenChange({refresh_token: e.target.value})}
                />
                <Button label="generate" onClick={() => onTokenChange({refresh_token: generateToken()})} />
              </div>
            </div>
            <label className="p-col-2">expires</label>
            <div className="p-col-2">
              <Calendar
                value={refreshTokenExpires}
                onChange={e => onTokenChange({refresh_token_expires: e.value as Date | undefined})}
                showIcon
                dateFormat={formats.calendarInputDate}
                showTime
              />
            </div>
          </div>
        </>
      )}
      {token?.type === 'email' && (
        <>
          <div className="p-field p-grid">
            <label className="p-col-2">email from</label>
            <div className="p-col-10">
              <InputText value={token?.email_from} onChange={e => onTokenChange({email_from: e.target.value})} />
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2">email to</label>
            <div className="p-col-10">
              <InputText value={token?.email_to} onChange={e => onTokenChange({email_to: e.target.value})} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
