import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AppContext, TwoEntityComponent, TwoEntityPanel, TwoToast} from 'two-app-ui';
import {B2bRequest, B2bRequestAggregate, DocumentItem, DocumentItemJsonModel, QueryParameter} from 'two-core';
import B2bRequestsService from '../../services/B2bRequestsService';
import {ProgressSpinner} from 'primereact/progressspinner';
import {B2bRequestDetail} from './B2bRequestDetail';
import {faDownload, faFile, faUpload} from '@fortawesome/pro-regular-svg-icons';
import {DocumentItemsForm} from '../DocumentItemsForm/DocumentItemsForm';

interface RouteProps {
  id: string;
}
interface State {
  b2bRequest?: B2bRequest;
  loading?: boolean;
}

class B2bRequestPage extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  b2bRequestsService?: B2bRequestsService;
  twoToast?: TwoToast;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.b2bRequestsService = this.context.b2bRequestsService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    this.setState({loading: true});
    const b2bRequestId = parseInt(this.props.match.params.id);
    const b2bRequest = await this.loadB2bRequest(b2bRequestId);
    if (!b2bRequest) {
      this.setState({loading: false});
      return;
    }
    this.setState({loading: false, b2bRequest});
  }

  async loadB2bRequest(b2bRequestId: number) {
    try {
      const filters = [JSON.stringify({field: 'id', value: b2bRequestId})];
      const aggregate: B2bRequestAggregate[] = ['b2b_integration'];
      const queryParams: QueryParameter = {filters, aggregate};
      const response = await this.b2bRequestsService?.getB2bRequests(queryParams);
      const b2bIntegrations = (response?.records ?? []) as B2bRequest[];
      return b2bIntegrations?.[0];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading b2b integration');
      return undefined;
    }
  }
  render() {
    const {b2bRequest, loading} = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!b2bRequest) {
      return <></>;
    }

    const documentItems =
      b2bRequest.order_snapshot?.items?.map((json: DocumentItemJsonModel) => new DocumentItem(json)) ?? [];

    return (
      <>
        <TwoEntityComponent title={b2bRequest.id ?? ''} actions={[]}>
          <TwoEntityPanel isPrimary={true}>
            <B2bRequestDetail b2bRequest={b2bRequest} />
          </TwoEntityPanel>
          <TwoEntityPanel icon={faDownload}>
            <div style={{whiteSpace: 'break-spaces'}}>{JSON.stringify(b2bRequest.content, null, 4)}</div>
          </TwoEntityPanel>
          <TwoEntityPanel icon={faUpload}>
            <div style={{whiteSpace: 'break-spaces'}}>{JSON.stringify(b2bRequest.response, null, 4)}</div>
          </TwoEntityPanel>
          <TwoEntityPanel icon={faFile}>
            <DocumentItemsForm mode="readonly" documentItems={documentItems} />
          </TwoEntityPanel>
        </TwoEntityComponent>
      </>
    );
  }
}

export default withRouter(B2bRequestPage);
